@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: -1px;
  right: -1px;
  bottom: -1px;
  max-height: 25rem;
  color: $vp-text;
  background-image: linear-gradient(transparent, $vp-bg);
  transition: opacity 300ms ease-out;
  z-index: 15;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  section:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4rem;
    margin-top: auto;
  }

  section:last-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    padding: 0 2rem 1rem 2rem;
    gap: 2rem;

    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 45rem;
      margin: auto;

      > div {
        display: flex;
        aspect-ratio: 1/1;
      }
    }
  }
}
