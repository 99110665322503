.menu {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35rem;
  height: 100vh;
  background-color: var(--bg-primary);
  will-change: transform;
  z-index: 110;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 3rem 3rem 3rem;
    margin-top: 10rem;
    gap: 2rem;
    font-size: 1.7rem;
  }

  a {
    display: flex;
    align-items: center;
    gap: 1rem;

    &.active {
      opacity: 0.7;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border: 1px solid var(--text-secondary);
    border-radius: 5px;

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__info {
      display: flex;
      gap: 2rem;
    }
  }

  &__navigation {
    width: 100%;
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    gap: 1.5rem;

    li:last-child {
      margin-top: auto;
      margin-bottom: 2rem;
    }
  }

  /* CSS Transition */

  &-enter {
    transform: translateX(100%);
  }
  &-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
  }
  &-exit {
    transform: translateX(0%);
  }
  &-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}
