$button-bg: hsl(0, 0%, 10%);
$button-text: hsl(0, 0%, 90%);
$button-disabled: hsl(0, 0%, 49%);

.swiper {
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
    .swiper-button-disabled {
      opacity: 0.4;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 4rem;
  height: 6rem;
  background-color: rgba($button-bg, 0.4);
  color: $button-text;
  opacity: 0;
  transition: background-color 200ms ease-out, opacity 200ms ease-out;

  &::after {
    font-size: 2.5rem;
    font-weight: bolder;
  }

  &:not(.swiper-button-disabled):hover {
    background-color: rgba($button-bg, 0.7);
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: initial;
  }
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-rtl {
  .swiper-button-prev {
    right: 0;
  }

  .swiper-button-next {
    left: 0;
  }
}

.swiper-pagination {
  padding: 1rem;
  bottom: 0 !important;
}

.swiper-pagination-bullet {
  width: 1.2rem;
  height: 1.2rem;
  background-color: $button-disabled;
  opacity: 1;
  transition: opacity 200ms ease-out;

  &:hover {
    opacity: 0.7;
  }
}

.swiper-pagination-bullet-active {
  background-color: $button-text;
}
