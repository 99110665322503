.navigation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: auto;
  gap: 2.5rem;

  &__toggle {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    &::after {
      content: '';
      border-top: 1rem solid var(--text-primary);
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 0.25rem;
      margin-top: 0.5rem;
      border-radius: 100px;
      background-color: var(--bg-inversed);
      transform: scaleX(0);
      transition: transform 200ms ease-out;
    }

    &.active::after {
      transform: scaleX(1);
    }
  }

  @media (max-width: 500px) {
    &__toggle {
      display: flex;
    }

    ul {
      position: absolute;
      flex-direction: column;
      top: 100%;
      gap: 0;
      border: 1px solid var(--text-primary);
      background-color: var(--bg-primary);
      clip-path: inset(0 0 100% 0);
      transition: clip-path 200ms ease-out;
    }

    li {
      padding: 2rem 3rem;
    }

    &:hover ul {
      clip-path: inset(0);
    }
  }
}
