@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__records {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
