.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  gap: 1rem;

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--bg-primary-low-opacity);
    z-index: 50;
  }

  > div {
    width: 1rem;
    height: 1rem;
    background-color: var(--bg-inversed);
    animation: loading-spinner 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

@keyframes loading-spinner {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
