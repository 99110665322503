.video-group {
  &:hover &__label a::after {
    opacity: 1;
  }

  &__loader {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  &__label {
    width: fit-content;
    margin-bottom: 2rem;

    &.loading {
      opacity: 0;
      pointer-events: none;
    }

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: inherit;

      &::after {
        content: '»';
        opacity: 0;
        transition: opacity 200ms ease-out;
      }
    }
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: initial;
    bottom: 100%;
    color: var(--text-primary);
    background-color: transparent;
    z-index: 1;
  }

  .swiper-button-prev {
    left: initial;
    right: 4rem;
  }

  @media (hover: hover) {
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;

        &:hover {
          background-color: transparent;
        }
        &:not(.swiper-button-disabled):hover {
          opacity: 0.7;
        }
      }
      .swiper-button-disabled {
        opacity: 0.4;
      }
    }
  }

  @media (hover: none) {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
    .swiper-button-disabled {
      opacity: 0.4;
    }
  }

  @media (max-width: 1113px) {
    &__loader {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
