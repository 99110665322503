.video-timestamp {
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
  transition: opacity 200ms ease-out;

  &:hover {
    opacity: 0.7;
  }
}
