.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 50px;
  overflow: hidden;

  svg,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    transition: opacity 200ms ease-out;
  }
}
