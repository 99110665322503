@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-error {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
  color: var(--text-invalid);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;

  svg {
    width: 5rem;
    height: 5rem;
  }
}
