.not-found {
  width: 100%;
  padding: 3rem;
  border-radius: 5px;
  border: 2px solid var(--text-secondary);
  color: var(--text-secondary);
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2rem;

  svg {
    width: 5rem;
    height: 5rem;
  }
}
