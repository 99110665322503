@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__progress {
  position: relative;
  width: 100%;
  height: 100%;

  &__range {
    @include vp-range($vp-accent--primary);
    width: 100%;
    height: 100%;
  }

  &__tooltip {
    position: absolute;
    bottom: 5rem;
    padding: 0.5rem 0.75rem;
    color: $vp-text;
    background-color: rgba($vp-bg, 0.8);
    border-radius: 5px;
    font-weight: 700;
    pointer-events: none;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 200ms ease-out;
  }

  @media (hover: hover) {
    &:hover &__tooltip {
      opacity: 1;
    }
  }
}
