.channel-item {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3rem;
    gap: 2rem;
    border-radius: 5px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    gap: 1.5rem;
  }

  &__name {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
