.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  gap: 1rem;
  border: 1px solid;
  border-color: var(--text-secondary);
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--text-inversed);
  background-color: var(--bg-inversed);
  overflow: hidden;
  transition: all 200ms ease-out;

  &:hover {
    opacity: 0.7;
  }

  &:disabled,
  &:hover:disabled,
  &:active:disabled,
  &:disabled svg,
  &.inversed:disabled,
  &.inversed:active:disabled,
  &.inversed:hover:disabled,
  &.inversed:disabled svg {
    color: var(--text-disabled);
    background-color: var(--bg-disabled);
    border-color: var(--bg-disabled);
    cursor: not-allowed;
    opacity: 1;
  }

  &.inversed {
    color: var(--text-primary);
    background-color: var(--bg-primary);
    border-color: var(--text-secondary);
  }

  &.invalid {
    color: var(--text-primary);
    background-color: var(--bg-invalid);
    border-color: var(--bg-invalid);

    &:disabled,
    &:hover:disabled,
    &:active:disabled {
      color: var(--text-disabled);
      background-color: var(--accent-secondary);
      border-color: var(--accent-secondary);
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &.inversed.invalid {
    color: var(--text-invalid);
    background-color: var(--bg-primary);
    border-color: var(--text-invalid);
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
