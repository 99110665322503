.channel-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem;
  gap: 3rem;
  background-image: linear-gradient(
    110deg,
    var(--bg-tertiary) 15%,
    var(--bg-tertiary-high-opacity) 25%,
    var(--bg-tertiary) 35%
  );
  background-size: 200% 500%;
  animation: loading-card 1.5s linear infinite;

  @keyframes loading-card {
    to {
      background-position-x: -200%;
    }
  }

  &__avatar {
    min-width: 8rem;
    min-height: 8rem;
    border-radius: 50%;
    background-color: var(--bg-secondary);
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;

    > div {
      width: 100%;
      height: 1.5rem;
      border-radius: 50px;
      background-color: var(--bg-secondary);

      &:nth-child(2) {
        width: 70%;
      }

      &:nth-child(3) {
        width: 60%;
      }
    }
  }
}
