@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__rewind {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > *:first-child {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-in-out;
  }

  @media (hover: hover) {
    &:hover > *:first-child {
      opacity: 1;
      visibility: visible;
      transform: translateX(-200%);
    }
  }
}
