.global-message-item {
  margin-left: auto;
  font-weight: 500;
  color: var(--text-inversed);
  background-color: var(--bg-tertiary);
  cursor: pointer;
  transform-origin: right;
  transition: transform 300ms ease-out, opacity 300ms ease-out,
    background-color 200ms ease-out;

  &:hover {
    background-color: var(--bg-secondary);
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    gap: 1rem;
  }

  &__timer {
    width: 100%;
    height: 2px;
    background-color: var(--text-primary);
    animation: message-timer 1s ease-out;
  }

  &.message {
    color: var(--text-success);
  }

  &.error {
    color: var(--text-invalid);
  }

  // CSS Transition

  &-enter {
    transform: translateX(10%);
    opacity: 0;

    &-active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &-exit {
    transform: translateX(0);
    opacity: 1;

    &-active {
      transform: translateX(10%);
      opacity: 0;
    }
  }
}

@keyframes message-timer {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}
