.video-loader {
  width: 100%;
  height: max-content;
  background-color: var(--bg-tertiary);
  overflow: hidden;

  &__thumbnail {
    width: 100%;
    background-image: linear-gradient(
      110deg,
      var(--bg-secondary) 15%,
      var(--bg-secondary-high-opacity) 25%,
      var(--bg-secondary) 35%
    );
    background-size: 200% 100%;
    animation: loading-card 1.5s linear infinite;
    aspect-ratio: 16/9;

    @keyframes loading-card {
      to {
        background-position-x: -200%;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    width: 100%;
  }

  &__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--bg-secondary);
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 5rem;
    padding: 1rem;
    gap: 1rem;
  }

  &__title {
    width: 100%;
    height: 1rem;
    border-radius: 50px;
    background-color: var(--bg-secondary);
  }

  &__description {
    width: 60%;
    height: 1rem;
    border-radius: 50px;
    background-color: var(--bg-secondary);
  }
}
