.video-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: auto;
  overflow: hidden;
  transition: transform 300ms ease-out, box-shadow 300ms ease-out;

  &__thumbnail {
    position: relative;
    width: 100%;
    heigh: 100%;

    img {
      transition: transform 300ms ease-out;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__duration {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.5rem;
    background-color: hsla(0, 0%, 0%, 0.5);
    color: hsl(0, 0%, 90%);
  }

  &__history {
    position: absolute;
    top: 100%;
    height: 3px;
    background-color: var(--accent-primary);
    transition: opacity 300ms ease-out;
    z-index: 1;
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    gap: 1rem;
    background-color: var(--bg-primary);
  }

  &__header {
    display: flex;
    width: 100%;
    margin-bottom: auto;
    gap: 1rem;
  }

  &__title {
    width: fit-content;
    margin-bottom: 1rem;
    margin-right: auto;
    font-weight: 600;
    font-size: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 1rem;
  }

  &__data {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
}
