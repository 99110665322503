@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  background-color: rgba($vp-bg, 0.5);
  z-index: 30;

  div {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    box-shadow: 0.05em 0.05em 0 0 $vp-accent--primary;
    transform-origin: 0.5em;
    animation: vp-loader 1s linear infinite;
  }

  @keyframes vp-loader {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
}
