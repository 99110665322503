.video-thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: var(--bg-tertiary);
  aspect-ratio: 16/9;

  img,
  video {
    position: absolute;
  }

  svg {
    width: 40%;
    height: 40%;
  }
}
