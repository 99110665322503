$vp-bg: hsl(0, 0%, 0%);
$vp-bg--secondary: hsl(0, 0%, 52%);
$vp-bg--tertiary: hsl(0, 0%, 20%);
$vp-text: hsl(0, 0%, 100%);
$vp-accent--primary: hsl(357, 83%, 44%);
$vp-accent--secondary: hsl(2, 100%, 21%);
$vp-range--selection-time: hsl(357, 83%, 75%);

@mixin vp-range($color) {
  position: relative;
  display: flex;
  align-items: center;

  &--background {
    position: absolute;
    width: 100%;
    height: 10%;
    border-radius: 50px;
    background-color: $vp-bg--secondary;
  }

  &--buffer {
    position: absolute;
    width: 100%;
    height: 10%;
    border-radius: 50px;
    background-color: $vp-accent--secondary;
    transition: width 200ms ease-out;
  }

  &--current {
    position: relative;
    display: flex;
    align-items: center;
    width: 0%;
    height: 10%;
    border-radius: 50px;
    background-color: $color;

    &__thumb {
      position: absolute;
      right: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 50px;
      background-color: $color;
      transform: translateX(50%) scale(0);
      transition: transform 200ms ease-out;
    }
  }

  &--selection-time {
    position: absolute;
    width: 100%;
    height: 10%;
    border-radius: 50px;
    background-color: $vp-range--selection-time;
  }

  &--seek {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  @media (hover: hover) {
    &:hover &--current__thumb {
      transform: translateX(50%) scale(1);
    }
  }

  @media (hover: none) {
    &--current__thumb {
      transform: translateX(50%) scale(1);
    }
  }
}

.vp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $vp-text;
  background-color: $vp-bg;
  outline: none;

  video {
    width: 100%;
    height: 100%;
    z-index: 1;

    @media (hover: none) {
      pointer-events: none;
    }
  }
}
