.search {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 4.5rem;
    height: 4.5rem;
    padding-left: 4.5rem;
    border: none;
    outline: none;
    color: var(--text-primary);
    background-color: transparent;
    transition: width 300ms ease-out;

    &:focus {
      width: clamp(20rem, 35vw, 30rem);
    }
  }

  label {
    position: absolute;
    display: flex;
    left: 0;
  }

  svg {
    width: 4.5rem;
    height: 4.5rem;
    padding: 1rem;
  }
}
