@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-selector {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 4rem;
  gap: 2rem;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50%);
  transition: transform 400ms ease-out, opacity 400ms ease-out;
  z-index: 15;

  &.active {
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0);
  }

  &__container {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-content: flex-end;
    gap: 2rem;
  }

  &__timer {
    font-weight: 600;
    text-align: center;
    text-shadow: 0.1rem 0 black, 0 0.1rem black, -0.1rem 0 black,
      0 -0.1rem black, -0.1rem -0.1rem black, -0.1rem 0.1rem black,
      0.1rem -0.1rem black, 0.1rem 0.1rem black;
    opacity: 0;
    transition: opacity 300ms ease-out;

    &.active {
      opacity: 1;
    }
  }

  &__btn {
    width: 35%;
    padding: 1.5%;
    border: 1px solid $vp-bg--tertiary;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-size: inherit;
    color: $vp-text;
    background-color: rgba($vp-bg, 0.8);
    transition: background-color 200ms ease-out;

    &:hover {
      background-color: $vp-bg--tertiary;
    }
  }

  &__btn-enter {
    opacity: 0;
  }
  &__btn-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
  &__btn-exit {
    opacity: 1;
  }
  &__btn-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
}
