@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__settings__dropdown {
  position: absolute;
  bottom: 100%;
  right: 1px;
  width: clamp(30rem, 35%, 40rem);
  overflow: hidden;
  transition: opacity 200ms ease-out, height 300ms ease-out;
  background-color: rgba($vp-bg, 0.8);

  &__menu {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: transform 300ms ease;
  }

  &__list {
    overflow: auto;
  }

  &__label,
  &__item {
    display: flex;
    align-items: center;
    padding: 5% 10%;
    gap: 1rem;
    cursor: pointer;
    transition: background-color 200ms;

    &:hover {
      background-color: rgba($vp-bg--tertiary, 0.8);
    }
  }

  &__label {
    font-weight: 600;
  }

  &__item {
    justify-content: space-between;
  }

  &__item.active::after {
    content: '';
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    border-radius: 50px;
    background-color: $vp-text;
  }
}

/* CSS Transition */

.vp-dropdown-enter {
  opacity: 0;
}
.vp-dropdown-enter-active {
  opacity: 1;
}
.vp-dropdown-exit-active {
  opacity: 0;
}

.vp-menu-index-enter {
  transform: translateX(-110%);
}
.vp-menu-index-enter-active {
  transform: translateX(0%);
}
.vp-menu-index-exit-active {
  transform: translateX(-110%);
}

.vp-menu-main-enter {
  transform: translateX(110%);
}
.vp-menu-main-enter-active {
  transform: translateX(0%);
}
.vp-menu-main-exit-active {
  transform: translateX(110%);
}
