@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-key-action {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__volume {
    position: absolute;
    display: flex;
    top: -1px;
    bottom: 75%;
    left: -1px;
    right: -1px;
    background-image: linear-gradient(rgba($vp-bg, 0.7), transparent);
    transition: opacity 300ms ease-out;
    z-index: 15;

    &__container {
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      margin: auto;
      gap: 2rem;
      width: clamp(12rem, 20%, 25rem);
      height: auto;
      transition: all 300ms ease-out;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &__range {
      @include vp-range($vp-text);
      width: 100%;
      height: 100%;
      margin: auto;

      &--background,
      &--current {
        height: 10%;
        transition: width 200ms ease-out;
      }
    }

    /* CSS Transition */

    &-enter {
      opacity: 0;

      > div {
        opacity: 0;
        transform: translateY(-100%);
      }

      &-active {
        opacity: 1;

        > div {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &-exit {
      opacity: 1;

      > div {
        opacity: 1;
        transform: translateY(0);
      }

      &-active {
        opacity: 0;

        > div {
          opacity: 0;
          transform: translateY(-100%);
        }
      }
    }
  }

  &__progress {
    position: absolute;
    display: flex;
    top: -1px;
    bottom: -1px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    z-index: 20;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &.rewind {
      left: -1px;
      right: 50%;
      background-image: linear-gradient(
        to left,
        transparent,
        rgba($vp-bg, 0.7)
      );
    }

    &.skip {
      left: 50%;
      right: -1px;
      background-image: linear-gradient(
        to right,
        transparent,
        rgba($vp-bg, 0.7)
      );
    }

    svg {
      width: clamp(5rem, 20%, 10rem);
      height: 100%;
      margin: auto;
    }
  }
}
