@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(2rem, 30%, 4.5rem);
  margin: auto;
  font-size: inherit;
  color: $vp-text;
  background-color: transparent;
  aspect-ratio: 1/1;

  svg {
    width: 100%;
    height: 100%;
    transition: transform 200ms;
  }

  &::before {
    content: attr(data-label);
    position: absolute;
    display: none;
    bottom: 150%;
    width: max-content;
    padding: 0.5rem 0.75rem;
    color: $vp-text;
    background-color: rgba($vp-bg, 0.8);
    font-weight: 600;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-out;
  }

  &.label::before {
    display: block;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: scale(1.3);
      }
    }

    &:hover::before {
      opacity: 1;
    }
  }

  @media (hover: none) {
    &:active {
      svg {
        transform: scale(1.3);
      }
    }

    &:active::before {
      opacity: 1;
    }
  }
}
