.video-carousel {
  &__slider {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 2.5/1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 3rem;
    gap: 1rem;
  }

  &__title {
    font-size: 2rem;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  &__data {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 900px) {
    &__slider {
      aspect-ratio: 16/9;
    }

    &__slider:hover &__info {
      transform: translateX(0);
    }

    &__info {
      position: absolute;
      align-items: center;
      pointer-events: none;
      right: 0;
      width: 70%;
      height: 100%;
      padding: 4rem;
      background-color: hsla(0, 0%, 10%, 0.3);
      transform: translateX(100%);
      transition: transform 300ms;

      * {
        color: hsl(0, 0%, 80%);
      }
    }
  }
}
