@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__volume {
  grid-column: 2;
  position: relative;
  display: flex;
  align-items: center;

  &__range {
    @include vp-range($vp-text);
    position: absolute;
    left: 50%;
    width: clamp(6rem, 100%, 12rem);
    height: 6rem;

    > * {
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 200ms ease-out;
    }
  }

  @media (hover: hover) {
    &:hover svg {
      transform: translateX(clamp(-7rem, -100%, -3rem)) scale(1.3);
    }

    &:hover &__range > * {
      transform: scaleX(1);
    }
  }

  @media (hover: none) {
    &__range--current__thumb {
      opacity: 0;
    }

    svg {
      transform: translateX(clamp(-7rem, -100%, -3rem));
    }

    &__range > * {
      transform: scaleX(1);
    }
  }
}
