.video-grid {
  &:hover &__label a::after {
    opacity: 1;
  }

  &__container,
  &__loader {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    justify-content: center;
    align-items: center;
    gap: 3rem 2rem;
  }

  &__label {
    width: fit-content;
    margin-bottom: 2rem;

    &.loading {
      opacity: 0;
      pointer-events: none;
    }

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: inherit;

      &::after {
        content: '»';
        opacity: 0;
        transition: opacity 200ms ease-out;
      }
    }
  }

  @media (max-width: 1113px) {
    &__container,
    &__loader {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
