.video-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.video-tag {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: var(--bg-secondary);
}
