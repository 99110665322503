.video-node {
  position: absolute;
  display: none;
  inset: 0;
  width: 100%;
  height: 100%;

  &[data-active='true'] {
    position: static;
    display: block;
  }

  &__not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 2rem;
    font-size: 2.5rem;
    background-color: var(--bg-primary);
  }
}
