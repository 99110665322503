.video-layout {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 0 0.1rem;
  overflow: auto;
  background-color: hsl(0, 0%, 0%);
  scrollbar-width: none;
  z-index: 120;

  &::-webkit-scrollbar {
    display: none;
  }

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}
