@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-controls__records__modal {
  position: absolute;
  bottom: 100%;
  width: 100%;
  overflow-x: auto;
  background-color: rgba($vp-bg, 0.4);

  &__container {
    width: 100%;
    height: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 2rem 0;
    gap: 2rem;
    text-align: center;
  }

  &__image {
    display: flex;
    width: 100%;
    aspect-ratio: 16/9;
  }
}

/* CSS Transition */

.vp-modal-enter {
  opacity: 0;
}
.vp-modal-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
}
.vp-modal-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}
