.video-creator {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  gap: 1rem;

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
