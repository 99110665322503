.video-dropdown {
  display: flex;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    transition: background-color 200ms ease-out;

    &:hover {
      background-color: var(--bg-tertiary);
      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-primary);
    z-index: 1;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: auto;
      transform: rotate(90deg);
    }
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 200ms ease-out;

    &:hover {
      background-color: var(--bg-tertiary);
    }
  }

  /* CSS Transition */

  &-enter {
    transform: translateY(100%);
    transform-origin: bottom right;
    transition: transform 300ms;

    &-active {
      transform: translateY(0);
    }
  }

  &-exit {
    transform: translateY(0);
    transform-origin: bottom right;
    transition: transform 300ms;

    &-active {
      transform: translateY(100%);
    }
  }
}
