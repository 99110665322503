.video-tree {
  position: relative;
  width: 100%;
  max-height: 100vh;
  aspect-ratio: 16/9;

  @media (max-aspect-ratio: 16/9) {
    height: 100%;
  }
}
