.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  /* CSS Transition */

  &-enter {
    opacity: 0;
    transition: opacity 300ms;

    &-active {
      opacity: 1;
    }
  }

  &-exit {
    opacity: 1;
    transition: opacity 300ms;

    &-active {
      opacity: 0;
    }
  }
}
