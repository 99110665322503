.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  margin: 0 0.5rem;

  svg {
    width: 100%;
    height: 100%;
  }
}
