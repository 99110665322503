.theme-light .header {
  background-color: var(--bg-primary-high-opacity);
  background-image: none;
}

.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  padding: 0 2rem;
  gap: 2.5rem;
  font-weight: 600;
  text-align: center;
  background-image: linear-gradient(var(--bg-primary), transparent);
  transition: background-color 200ms ease-out;
  z-index: 110;

  &:hover,
  &.menu-opened {
    background-color: var(--bg-primary);
  }
}
