@use '../../Player/VideoPlayer.scss' as *;

.preview-player {
  &__playback,
  &__volume {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $vp-text;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;

    &[data-active='true'] {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__playback {
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($vp-bg, 0.5);

    svg {
      width: clamp(3rem, 30%, 5rem);
      height: auto;
    }
  }

  &__volume {
    bottom: 2rem;
    right: 2rem;
    width: clamp(2.5rem, 20%, 5rem);
    height: auto;
    padding: 1rem;
    border: 2px solid $vp-text;
    border-radius: 50px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__loader {
    cursor: pointer;
  }
}
