.menu-button {
  width: 4.5rem;
  height: 4.5rem;
  min-width: 4.5rem;
  min-height: 4.5rem;
  padding: 1.25rem;
  gap: 0.5rem;

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    > div {
      position: absolute;
      width: 100%;
      height: 0.2rem;
      background-color: var(--bg-inversed);
      border-radius: 100px;

      &:nth-child(1) {
        transform: translateY(-0.6rem);
      }

      &:nth-child(3) {
        transform: translateY(0.6rem);
      }
    }

    &.on > div {
      &:nth-child(1) {
        animation: top-on 600ms ease-out forwards;
      }

      &:nth-child(2) {
        animation: center-on 600ms ease-out forwards;
      }

      &:nth-child(3) {
        animation: bottom-on 600ms ease-out forwards;
      }
    }

    &.off > div {
      &:nth-child(1) {
        animation: top-off 600ms ease-out forwards;
      }

      &:nth-child(2) {
        animation: center-off 600ms ease-out forwards;
      }

      &:nth-child(3) {
        animation: bottom-off 600ms ease-out forwards;
      }
    }
  }
}

@keyframes top-on {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes center-on {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottom-on {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(135deg);
  }
}

@keyframes top-off {
  0% {
    transform: rotate(45deg) translateY(0);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(-0.6rem);
  }
}

@keyframes center-off {
  0% {
    transform: rotate(45deg) translateY(0);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-off {
  0% {
    transform: rotate(135deg) translateY(0);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(0.6rem);
  }
}
