@use '/src/components/Video/Player/VideoPlayer.scss' as *;

.vp-header {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: -1px;
  right: -1px;
  height: clamp(5rem, 25%, 10rem);
  padding: 0 2rem;
  background-image: linear-gradient($vp-bg, transparent);
  transition: opacity 300ms ease-out;
  z-index: 15;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    width: clamp(2rem, 3%, 3.5rem);
    height: auto;
    aspect-ratio: 1/1;
  }
}
